@import "src/global";

.wrap {
  width: 30px;
  height: 30px;

  svg {
    animation: $spinner-animation;
  }
}

@include mobile {
  .wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
