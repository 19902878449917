@import "src/global";

.content {
  width: 100%;
  padding-bottom: $spacing-xxlarge;
}

.rotate {
  animation: rotate $options-block-animation linear;
}

@include mobile {
  .modal {
    width: calc(100vw - 35px);
  }
}
