@import "src/global";

.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
  overflow-y: scroll;
  padding: 20px;
}

.title {
  font-size: 30px;
  color: $gray-100;
}

.description {
  font-size: 22px;
  color: $gray-90;
  max-width: 750px;
}

@include mobile {
  .title {
    font-size: 20px;
  }

  .description {
    font-size: 15px;
  }
}
