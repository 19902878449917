@import "src/global";

.line {
  display: flex;
  align-items: flex-start;
  column-gap: $spacing-medium;

  > div {
    width: 100%;
  }
}

.field {
  margin-bottom: 20px;
}

.tiny-field {
  margin-bottom: $spacing-medium;
}
