@import "src/global";

.section {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.row {
  display: flex;
  column-gap: 30px;

  > div {
    width: 50%;
  }

  .left-section {
    width: 45%;
  }
}

@include mobile {
  .row {
    flex-direction: column;
    row-gap: 15px;

    > div {
      width: 100%;
    }

    .left-section {
      width: 100%;
    }
  }
}
