@import "src/global";

.get-link-buttons {
  display: flex;
  justify-content: center;
  column-gap: $spacing-large;
  margin-top: $spacing-base;
  margin-bottom: $spacing-base;
}

.notification {
  color: $gray-70;
}
