@import "src/global";

.anchor {
  white-space: nowrap;
}

.label {
  @include label-medium;
  color: $gray-90;
}

.variable {
  @include label-medium;
  color: $gray-80;
}

.icon {
  flex-shrink: 0;
}
